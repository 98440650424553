.home {
    width: 100vw;
    height: 100vh;
    background: #2c3e50;
    color: white;
    overflow-y: scroll;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.title {
    font-size: 50px;
    margin: 0 0 10vh;
    text-align: center;
}

@media screen and (max-width: 440px) {
    .name-wrapping-div-home {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .name-wrapping-div-home input.box-wrapper {
        margin: 0 0 15px;
    }

    .developed-at p {
        font-size: 22px;
        text-align: center;
    }

    .developed-at span {
        display: block;
    }

    .full-height-div .devs {
        flex-direction: column;
        margin: 0;
    }

    .full-height-div .devs .dev1 {
        margin-bottom: 20px;
    }
}

.name-wrapping-div-home .box-wrapper {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.18);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    font-size: large;
    padding: 15px 10px;
    text-align: center;
}

.name-wrapping-div-home input.box-wrapper {
    margin-right: 20px;
    padding: 15px 40px;
}

.developed-at {
    background: #D9D9D9;
    height: 10vh;
    color: black;
    display: flex;
    font-size: xx-large;
    align-items: center;
    justify-content: center;
}

.developed-at span {
    font-weight: 600;
}

#name-input-home::placeholder {
    color: white;
}

.full-height-div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow:column wrap
}

.ninety-height-div {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#title {
    font-size: xxx-large;
}

.create-game-div {
    width: 500px;
    display: flex;
    flex-flow:column wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 100px;
    border: 2px solid #ecf0f1;
    border-radius: 25px;
}

.create-game-div > input {
    width: 300px;
    height: 50px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-align: center;
}

.create-game-div > button {
    box-sizing: border-box;
    width: 300px;
    height: 50px;
    padding: 0;
}

.devs {
    margin-top: 10vh;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
}

.devs > div {
    display: flex;
    flex-flow:column wrap;
    align-items: center;
}

.devs > div > a > img {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 10px black;
}

.links {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.links > a > img {
    height: 30px;
    width: 30px;
    object-fit: contain;
    filter: invert(100%);
    cursor: pointer;
}

.devs > div > h2 {
    margin-bottom: 0;
    padding: 0;
}
.devs > div > h4 {
    margin-top: 0;
    padding: 0;
}