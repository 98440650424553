.control-wrapper {
    margin-left: 40px;
    max-width: 300px;
}

.control-wrapper .current-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.player-card {
    width: 78px;
    height: 100px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    position: relative;
}

.player-card:hover {
    box-shadow: 0 4px 10px 3px #ccc;
}

.player-card .card-image {
    width: 53px;
}

.deck-wrapper .player-card {
    margin-bottom: 0;
}

.control-wrapper .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.control-wrapper .content-wrapper .number {
    font-size: 20px;
    color: #DD1A1E;
}

.control-wrapper .content-wrapper .number.spade,
.control-wrapper .content-wrapper .number.club {
    color: #000;
}

.control-wrapper .content-wrapper .top-left {
    position: absolute;
    top: 3px;
    left: 5px;
}

.control-wrapper .content-wrapper .bottom-right {
    position: absolute;
    bottom: 3px;
    right: 5px;
}

.control-wrapper .content-wrapper .suit {
    width: 20px;
}

.control-wrapper .content-wrapper .suit.top {
    position: absolute;
    left: 40%;
    top: 25%;
}

.control-wrapper .content-wrapper .suit.bottom {
    position: absolute;
    left: 40%;
    top: 55%;
}

.player-card .discard-wrapper {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    background: #dd1a1e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.deck-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.deck-wrapper .pending {
    margin-left: 10px;
}

.deck-wrapper .pending .pending-cards {
    font-size: 16px;
    width: 80px;
    height: 57px;
    text-align: center;
}

.deck-wrapper .deck {
    width: 78px;
    height: 100px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deck-wrapper .deck .count {
    position: absolute;
}

.deck-wrapper .deck .count span {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}

.deck-wrapper .deck img {
    width: 64px;
    height: 87px;
}

.deck-wrapper .used-deck {
    display: flex;
    align-items: center;
}

.score-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.score-wrapper .team {
    display: flex;
    margin-bottom: 10px;
    font-weight: 500;
    cursor: pointer;
}

.score-wrapper .team .name {
    width: 180px;
    background: #DD1A1E;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    margin-right: 15px;
    font-size: 17px;
}

.score-wrapper .team .team-name {
    font-weight: bolder;
    position: relative;
    display: inline-block;
    width: 180px;
}

.score-wrapper .team .team-name .drop-down-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.score-wrapper .team .players {
    transition: all 2s;
}

.score-wrapper .team .score {
    width: 40px;
    background: #DD1A1E;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
}

.score-wrapper .team.team-blue .name,
.score-wrapper .team.team-blue .score {
    background: #2980b9;
}

.score-wrapper .team.team-green .name,
.score-wrapper .team.team-green .score {
    background: #27ae60;
}

.control-wrapper .turn-span {
    text-align: center;
    margin-bottom: 20px;
}

.control-wrapper .turn-span .turn {
    margin: 0;
    display: inline-block;
    color: #fff;
    font-weight: 700;
    padding: 10px;
    border-radius: 10px;
    opacity: 0.5;
}

.control-wrapper .turn-span .turn.can-play {
    opacity: 1;
}

.control-wrapper .turn-span .turn.red {
    background: #dd1a1e;
}

.control-wrapper .turn-span .turn.blue {
    background: #2980b9;
}

.control-wrapper .turn-span .turn.green {
    background: #27ae60;
}

.end-wrapper {
    background: #000;
    border-radius: 10px;
    margin-top: 30px;
    padding: 15px;
    text-align: center;
    color: #fff;
    width: 100%;
}

.end-wrapper h3 {
    font-size: 26px;
    margin: 0 0 20px;
}

.end-wrapper span {
    font-size: 22px;
    font-weight: 600;
}

.end-wrapper span.red {
    color: #dd1a1e;
}

.end-wrapper span.blue {
    color: #0000ff;
}

.end-wrapper span.score {
    margin: 0 20px;
}

.end-wrapper .message {
    font-size: 18px;
    line-height: 26px;
}

.end-wrapper .message span {
    font-size: 18px;
    line-height: 26px;
}

.end-wrapper button {
    background: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

@media (max-width: 440px) {
    .control-wrapper {
        margin: 0;
        max-width: unset;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .player-card {
        width: 48px;
        height: 68px;
    }

    .player-card .card-image {
        width: 36px;
    }

    .control-wrapper .content-wrapper .number {
        font-size: 14px;
    }

    .control-wrapper .content-wrapper .top-left {
        top: 2px;
    }

    .control-wrapper .content-wrapper .bottom-right {
        bottom: 2px;
    }

    .control-wrapper .content-wrapper .suit {
        width: 13px;
        left: 36% !important;
    }

    .control-wrapper .turn-span {
        margin: 8px 0;
    }

    .control-wrapper .turn-span .turn {
        padding: 7px;
        font-size: 13px;
    }

    .bottom-section {
        display: flex;
    }

    .score-wrapper .team .name {
        width: unset;
        min-width: 100px;
        padding: 5px 8px;
        font-size: 14px;
        margin-right: 10px;
    }

    .score-wrapper .team .score {
        width: 34px;
        font-size: 16px;
    }

    .deck-wrapper .pending {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .deck-wrapper .pending .pending-cards {
        font-size: 10px;
        width: unset;
        height: unset;
        margin-top: 5px;
    }

    .deck-wrapper .deck {
        width: 48px;
        height: 68px;
    }

    .deck-wrapper .deck img {
        width: 38px;
        height: 57px
    }

    .score-wrapper.ended {
        display: none;
    }
}