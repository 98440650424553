.board-wrapper.no-play {
    pointer-events: none;
    opacity: 0.9;
}

.board-wrapper.end-game {
    pointer-events: none;
    opacity: 1;
}

.card {
    width: 85px;
    height: 60px;
    background: #fff;
    border-radius: 5px;
    margin: 2px 3px;
    display: inline-block;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0 4px 10px 3px #ccc;
}

.card .content {
    pointer-events: none;
    position: absolute;
    width: 85px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card .content .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #dd1a1e;
    z-index: 1;
    opacity: 0.5;
    border-radius: 5px;
    animation: fadeOut 5s;
    animation-fill-mode: forwards;
}

.card .content .overlay.blue {
    background: #0000ff;
}

.card .content .overlay.green {
    background: #00ff00;
}

.card .content .suit-wrapper .club {
    background: url("../../../assets/images/club.png") no-repeat;
}

.card .content .suit-wrapper .heart {
    background: url("../../../assets/images/heart.png") no-repeat;
}

.card .content .suit-wrapper .diamond {
    background: url("../../../assets/images/diamond.png") no-repeat;
}

.card .content .suit-wrapper .spade {
    background: url("../../../assets/images/spade.png") no-repeat;
}

.card .content .face-card {
    width: 65px;
    height: 40px;
}

.card .content .suit-wrapper .suit {
    background-size: contain;
    display: block;
    width: 15px;
    height: 15px;
}

.card .content .suit-wrapper .right {
    position: absolute;
    right: 10px;
    top: 22px;
}

.card .content .suit-wrapper .left {
    position: absolute;
    left: 10px;
    top: 22px;
}

.card .content .number-wrapper {
    font-size: 21px;
}

.card .content .number-wrapper .heart,
.card .content .number-wrapper .diamond {
    color: #DD1A1E;
}

.card .content .number-wrapper .club,
.card .content .number-wrapper .spade {
    color: #000;
}

.card .content .coin {
    position: absolute;
}

.card .content .coin img {
    width: 40px;
}

@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; display: none; }
}

@media (max-width: 440px) {

    .board-wrapper {
        display: flex;
    }

    .card {
        width: 30px;
        height: 50px;
        margin: 1px 2px;
    }

    .card .content {
        width: 30px;
        height: 50px;
    }

    .card .content .face-card {
        width: 25px;
        height: 45px;
    }

    .card .content .suit-wrapper .suit {
        width: 10px;
        height: 10px;
        left: 11px;
    }

    .card .content .suit-wrapper .left {
        top: 5px;
    }

    .card .content .suit-wrapper .right {
        top: 35px;
    }

    .card .content .number-wrapper {
        font-size: 16px;
    }

    .card .content .coin img {
        width: 24px;
    }
}