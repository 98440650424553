.jg-main {
  height: 100vh;
  background: #2c3e50;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.jg-main .jg-wrapper-div {
  width: 80vw;
  height: 80vh;
  border-radius: 25px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}
.jg-main .jg-wrapper-div .second-player-name-div {
  width: 100%;
  font-size: larger;
  font-weight: bolder;
  text-align: center;
}
.jg-main .jg-wrapper-div .second-player-name-div input {
  width: 50%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid black;
  margin-top: 20px;
  text-align: center;
  font-weight: bolder;
  font-size: large;
}
.jg-main .jg-wrapper-div .second-player-name-div button {
  width: 50%;
  height: 40px;
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
  background: #27ae60;
  border: 1px solid #2ecc71;
  color: white;
  font-weight: bolder;
  font-size: large;
}
.jg-main .jg-wrapper-div .share-ui-content {
  font-size: x-large;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}
.jg-main .jg-wrapper-div .share-ui-content .join-game-link {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: large;
  padding: 20px;
}
.jg-main .jg-wrapper-div .share-ui-content .join-game-link span {
  max-width: 80%;
}
.jg-main .jg-wrapper-div .share-ui-content .join-game-link img {
  filter: invert(1);
}
.jg-main .jg-wrapper-div .share-ui-content .teams {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.jg-main .jg-wrapper-div .share-ui-content .player {
  flex: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  cursor: pointer;
}
.jg-main .jg-wrapper-div .share-ui-content .player .header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 10px;
}
.jg-main .jg-wrapper-div .share-ui-content .player .team-contents {
  padding: 10px;
  font-size: medium;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jg-main .jg-wrapper-div .share-ui-content .player .team-contents span {
  margin-bottom: 8px;
}
.jg-main .jg-wrapper-div .share-ui-content .player .team-contents .no-player {
  color: lightgrey;
}
.jg-main .jg-wrapper-div .share-ui-content .message {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.jg-main .jg-wrapper-div .share-ui-content .start-game-button {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: x-large;
  font-weight: 600;
  padding: 6px 16px;
  position: relative;
  text-align: center;
}
.jg-main .jg-wrapper-div .share-ui-content .start-game-button:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}
.jg-main .jg-wrapper-div .share-ui-content .start-game-button:hover {
  background-color: #2c974b;
}
.jg-main .jg-wrapper-div .share-ui-content .start-game-button:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}
.jg-main .jg-wrapper-div .share-ui-content .start-game-button:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}
.jg-main .jg-wrapper-div .share-ui-content .start-game-button:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}


@media screen and (max-width: 440px) {

  .jg-main .jg-wrapper-div {
    padding: 25px;
  }

  .players-div .player {
      font-size: 16px;
  }

  .second-player-name-div button,
  .second-player-name-div input {
      width: 100%;
  }

  .jg-main .jg-wrapper-div .share-ui-content .join-game-link span {
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
  }

  .jg-main .jg-wrapper-div .share-ui-content .player {
    font-size: 12px;
    border-radius: 10px;
  }

  .jg-main .jg-wrapper-div .share-ui-content .player .header {
    border-radius: unset;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .jg-main .jg-wrapper-div .share-ui-content .player .team-contents .no-player {
    text-align: center;
  }

  .jg-main .jg-wrapper-div .share-ui-content .start-game-button {
    font-size: 18px;
    padding: 13px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 700px) {
  .jg-main .jg-wrapper-div .share-ui-content .join-game-link span {
    overflow: hidden;
  }
}