@mixin blurredBackground {
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(10px);
}

.jg-main {
  height: 100vh;
  background: #2c3e50;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .jg-wrapper-div {
    width: 80vw;
    height: 80vh;
    border-radius: 25px;
    padding: 25px;
    @include blurredBackground;
    display: flex;
    flex-direction: column;
    // TODO: Make justify content "space-between" when it's ShareUI
    justify-content: center;

    .second-player-name-div {
      width: 100%;
      font-size: larger;
      font-weight: bolder;
      text-align: center;

      input {
        width: 50%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid black;
        margin-top: 20px;
        text-align: center;
        font-weight: bolder;
        font-size: large;
      }

      button {
        width: 50%;
        height: 40px;
        border-radius: 10px;
        margin-top: 10px;
        text-align: center;
        background: #27ae60;
        border: 1px solid #2ecc71;
        color: white;
        font-weight: bolder;
        font-size: large;
      }
    }

    .share-ui-content {
      font-size: x-large;
      font-weight: bold;
      color: white;
      margin-bottom: 20px;

      .join-game-link {
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 25px;
        @include blurredBackground;

        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: large;
        padding: 20px;

        span {
          max-width: 80%;
        }

        img {
          filter: invert(1);
        }
      }

      .teams {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .player {
        flex: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 25px;
        @include blurredBackground;
        cursor: pointer;

        .header {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          padding: 10px;
        }

        .team-contents {
          padding: 10px;
          font-size: medium;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            margin-bottom: 8px;

            @media (max-width: 440px) {
              font-size: 12px;
              line-height: 16px;
              overflow: hidden;
            }
          }

          .no-player {
            color: lightgrey;
            font-size: small;
            padding: 10px;
          }
        }
      }

      .message {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      }

      .start-game-button {
        appearance: none;
        background-color: #2ea44f;
        border: 1px solid rgba(27, 31, 35, .15);
        border-radius: 6px;
        box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: x-large;
        font-weight: 600;
        padding: 6px 16px;
        position: relative;
        text-align: center;

        &:focus:not(:focus-visible):not(.focus-visible) {
          box-shadow: none;
          outline: none;
        }

        &:hover {
          background-color: #2c974b;
        }

        &:focus {
          box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
          outline: none;
        }

        &:disabled {
          background-color: #94d3a2;
          border-color: rgba(27, 31, 35, .1);
          color: rgba(255, 255, 255, .8);
          cursor: default;
        }

        &:active {
          background-color: #298e46;
          box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
        }
      }
    }
  }
}
